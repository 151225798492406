<template>
  <div class="surveyDiv">
    <form v-if="!submitted" @submit.prevent="submitForm">
      <p>მოგესალმებით!
        გახლავართ ქართულ-ამერიკული უნივერსიტეტის (GAU) დოქტორანტი ნინო ფანცულაია. წინამდებარე კვლევა განკუთვნილია
        სადოქტორო ნაშრომის შესრულებისათვის, არის ანონიმური და გამოყენებული იქნება არა კომერციული მიზნებისათვის, არამედ
        სადოქტორო ნაშრომში დასკვნებისა და რეკომენდაციების შემუშავებისთვის.
        კვლევა ეხება საერთაშორისო პროგრამების განხორციელებით უმაღლეს საგანმანათლებლო დაწესებულებებში ინტელექტუალური
        კაპიტალის განვითარებას.
        თქვენი მონაწილეობა მნიშვნელოვანია კვლევის განხორციელებისათვის, წინასწარ გიხდით დიდ მადლობას თანადგომისთვის!
      </p>
      <p>გთხოვთ, თქვენი გამოცდილებისა და თქვენს შეხედულებაზე დაყრდნობით მოახდინოთ ინტელექტუალური კაპიტალის განვითარების
        ჩამოთვლილი ინდიკატორების რანჟირება, თითოეულს მიანიჭეთ პროცენტული მაჩვენებელი, იმგვარად, რომ ჯამურმა მაჩვენებელმა
        შეადგინოს 100%-ი. (მითითება: რაც მეტი პროცენტული მაჩვენებლია, მით უფრო მნიშვნელოვანია თქვენი აზრით ინდიკატორი)</p>
      <p v-if="errorMessage" :style="styleObject">{{ errorMessage }}</p>

      <label for="cars">გთხოვთ აირჩიოთ რომელ ჯგუფს მიეკუთვნებით :</label>
      <select v-model="selectedOption">
        <option value="administration">აკადემიური/ადმინისტრაციული პერსონალი</option>
        <option value="student">კურსდამთავრებული/სტუდენტი</option>
      </select>
      <label for="q1">1. სტუდენტთა ჩარისხვისა და გარიცხვის მაჩვენებელი; </label>
      <input type="number" id="q1" v-model.number="q1" min="0" max="100"><br><br>

      <label for="q2">2. სტუდენტთა მოსწრების საშუალო შეწონილი ქულა - GPA</label>
      <input type="number" id="q2" v-model.number="q2" min="0" max="100"><br><br>

      <label for="q3">3. სტუდენტების დაფინანსება და თანადაფინანსება</label>
      <input type="number" id="q3" v-model.number="q3" min="0" max="100"><br><br>

      <label for="q4">4. გადამზადებული აკადემიური და ადმინისტრაციული პერსონალი </label>
      <input type="number" id="q4" v-model.number="q4" min="0" max="100"><br><br>

      <label for="q5">5. დასაქმება და სწავლის შემგომ საფეხურზე გაგრძელების ხელშეწყობა </label>
      <input type="number" id="q5" v-model.number="q5" min="0" max="100"><br><br>

      <label for="q6">6. პარტნიორების რაოდენობა ბიზნეს სექტორიდან და მოზიდული დონორი ორგანიზაციები</label>
      <input type="number" id="q6" v-model.number="q6" min="0" max="100"><br><br>

      <label for="q7">7. საუნივერსიტეტო ვებ-გვერდი და სოციალური მედიაის გზით განხორცილებული აქტივობები</label>
      <input type="number" id="q7" v-model.number="q7" min="0" max="100"><br><br>

      <label for="q8">8. თანამშრომლობის განვითარება საერთაშორისო და ადგილობრივ დონეზე</label>
      <input type="number" id="q8" v-model.number="q8" min="0" max="100"><br><br>

      <input id="myBtn" type="submit" :disabled="isDisabled" value="დადასტურება">
    </form>
    <div v-else>
      დიდი მადლობა გამოკითხვაში მონაწილეობისათვის!
    </div>
  </div>
  <div class="topcorner">
    <GChart type="PieChart" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts';
import axios from 'axios';

export default {
  name: 'HelloWorld',
  components: {
    GChart
  },
  props: {
    msg: String
  },
  data() {
    return {
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0,
      q5: 0,
      q6: 0,
      q7: 0,
      q8: 0,
      errorMessage: '',
      submitted: false,
      selectedOption: 'student',
      styleObject: {
        color: 'grey'
      },

      chartOptions: {
        chart: {
          title: 'Company Performance',
          subtitle: 'Sales, Expenses, and Profit: 2014-2017'
        }
      }
    };
  },
  created() {
    this.checkCookie();
  },
  watch: {
    q1: function () {
      this.checkTotal();
    },
    q2: function () {
      this.checkTotal();
    },
    q3: function () {
      this.checkTotal();
    },
    q4: function () {
      this.checkTotal();
    },
    q5: function () {
      this.checkTotal();
    },
    q6: function () {
      this.checkTotal();
    },
    q7: function () {
      this.checkTotal();
    },
    q8: function () {
      this.checkTotal();
    }
  },
  computed: {
    isDisabled() {
      // you can  check your form is filled or not here.
      return (this.q1 + this.q2 + this.q3 + this.q4 + this.q5 + this.q6 + this.q7 + this.q8) === 100 ? false : true
    },
    computedValue() {
      switch (this.selectedOption) {
        case 'student':
          return 'student';
        case 'professor':
          return 'professor';
        case 'administration':
          return 'administration';
        default:
          return 'student';
      }
    },
    chartData() {
      return [
        ['Questions', 'Question #'],
        ['კითხვა 1', this.q1],
        ['კითხვა 2', this.q2],
        ['კითხვა 3', this.q3],
        ['კითხვა 4', this.q4],
        ['კითხვა 5', this.q5],
        ['კითხვა 6', this.q6],
        ['კითხვა 7', this.q7],
        ['კითხვა 8', this.q8]
      ]
    }
  },
  methods: {
    checkTotal() {
      let total = this.q1 + this.q2 + this.q3 + this.q4 + this.q5 + this.q6 + this.q7 + this.q8;
      let totalLeft = 100 - total;
      if (total == 100) {
        this.errorMessage = 'შენ წარმატებით შეავსე ყველა კითხვა, გთხოვ დაადასტურო';
        this.styleObject.color = 'green';
      } else if (totalLeft < 0) {
        this.errorMessage = "შენ შეიყვანე არასწორი ციფრი";
        this.styleObject.color = 'red';
      } else {
        this.errorMessage = "ყველა პასუხის ჯამი უნდა უდრიდეს 100%." + "დაგრჩა " + totalLeft + "%";
        this.styleObject.color = 'orange';
      }
    },
    checkCookie() {
      let cookie = this.getCookie("form_submitted");
      if (cookie) {
        this.submitted = true;
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    setCookie(cname, cvalue, exdays) {
      let d = new Date();
      d.setTime(d.getTime() + exdays * 30 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    async submitForm() {
      const token = 'patriiBXMQm5AynxL.5191899a0ce8538c6f2c57e995522dc13d7eee8d849d6dcef93aa943f918d851';
      const data = {
        "records": [
          {
            "fields": {
              "ID": navigator.userAgent,
              "Type": this.computedValue,
              "Q1": this.q1,
              "Q2": this.q2,
              "Q3": this.q3,
              "Q4": this.q4,
              "Q5": this.q5,
              "Q6": this.q6,
              "Q7": this.q7,
              "Q8": this.q8
            }
          }
        ]
      };

      await axios.post('https://api.airtable.com/v0/appm0oJ9oevKWM1P9/surveytable', data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }).then(response => {
        console.log(response);
        this.submitted = true;
        this.setCookie("form_submitted", "true", 1);
      })
        .catch(error => {
          console.log(error);
        });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.topcorner {
  position: absolute;
  top: 0;
  right: 0;
}

select {
  padding: 10px;
  width: 50%;
  margin-bottom: 10px;
  font-size: 16px;
}

form {
  width: 500px;
  margin: auto;
  padding: 40px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px #ccc;
  text-align: center;
  font-family: Arial, sans-serif;
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
}

input[type="number"] {
  padding: 10px;
  width: 50%;
  margin-bottom: 10px;
  font-size: 16px;
}

input[type="submit"] {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #3e8e41;
}

#myBtn:disabled {
  cursor: not-allowed;
  background-color: grey;
  opacity: 0.8;
}

#error {
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
}

#success {
  color: rgb(0, 255, 13);
  font-size: 12px;
  margin-bottom: 10px;
}

#pending {
  color: rgb(255, 200, 0);
  font-size: 12px;
  margin-bottom: 10px;
}</style>
